import React from "react";
import Layout from "../../components/Layout";
import { compose } from "recompose";
import {
  withAuthorization,
  withActiveSubscription,
} from "../../components/Session";
import Measurements from "./Measurements";
import Workouts from "./Workouts";

const LogbookBase = () => {
  return (
    <section className="section">
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              Logbook
            </h1>
            <Measurements />
            <Workouts />
          </div>
        </div>
      </div>
    </section>
  );
};

const condition = (authUser) => !!authUser;

const Logbook = compose(
  withAuthorization(condition),
  withActiveSubscription
)(LogbookBase);

const LogbookFinal = () => (
  <Layout>
    <Logbook />
  </Layout>
);

export default LogbookFinal;
