import React from "react";
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import { format, fromUnixTime } from "date-fns";
import { withFirebase } from "../../components/Firebase";
import { useAuthUser } from "../../components/Session";

function Workouts({ firebase }) {
  const authUser = useAuthUser();
  const [data, setData] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [unsubscribe, setUnsubscribe] = React.useState();

  React.useEffect(() => {
    if (firebase && !loaded) {
      const unsub = firebase.workouts(authUser.uid).onSnapshot((snapshot) => {
        let dbWorkouts = [];

        snapshot.forEach((doc) => {
          dbWorkouts.push({ ...doc.data(), id: doc.id });
        });

        setData(dbWorkouts.sort((a, b) => b.date - a.date));
        setLoaded(true);
        setUnsubscribe(unsub);
      });
    }
    return () => unsubscribe;
  }, [firebase, loaded, unsubscribe, authUser]);

  let workouts = `You haven't recorded any workouts yet!`;
  if (data.length > 0) {
    workouts = data.map((item) => {
      const date = format(fromUnixTime(item.date), "MM/dd/yyyy");
      return (
        <div key={item.id}>
          ({date}){" "}
          {item.type === "weekly_workout" ? "Weekly Workout" : item.type}:{" "}
          {item.type === "weekly_workout" ? item.note : item.value}{" "}
        </div>
      );
    });
  }

  return (
    <div style={{ marginTop: "2rem" }}>
      <h4 className="title is-size-4 has-text-weight-bold is-bold-light">
        Workouts{" "}
      </h4>
      <div>
        {!loaded ? (
          <span>
            <FaSpinner className="fa-spin" /> Loading...
          </span>
        ) : (
          workouts
        )}
      </div>
    </div>
  );
}

export default withFirebase(Workouts);
